import React, { useEffect, useState } from "react";
import styles from "./styles.css";
import ContractIcon from "./images/contract.svg";
import PropTypes from "prop-types";
import { getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
import { useHistory } from "react-router-dom";
import { tasksRoutes } from "../../../constants/au.mobile/tasks-routes";
import Button from "../../shared/button";
import ArrowIcon from "../../shared/arrow";
import DocusignLoaderInfo from "../../shared/docusign-loader-info/component";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { LOAN_PROVIDER, NUMBER } from "../../../constants/app-constants";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { poll } from "../../../utils/polling";

const ContractSaleNote = ({
    content,
    signContractData,
    postSignContractConnect,
    order,
    getVerificationStatusConnect,
    verifyIdPollStatusConnect,
    signContractPollStatusConnect,
    getSignContractConnect,
    showToastConnect,
    customSignClick
}) => {
    const { year, make, model } = content;
    const {financeProvider, financeOpted, orderId} = order;
    const history = useHistory();
    const { documentType = "SALE"} = signContractData[0] || {};
    const { appointmentId } = content || {};
    const [ docuSignLoading, setDocuSignLoading ] = useState(false);

    const handleBackClick = () => {
        if (customSignClick) {
            customSignClick();
        } else {
            const { relativeURL } = getMyBookingCarDetailsURL(appointmentId, tasksRoutes.viewTask.route);
            history.push(relativeURL);
        }
    };

    const signInContractDataAdded = (response) => {
        if (response && Array.isArray(response) && response.length > 0) {
            signContractPollStatusConnect(true);
            history.replace(window.location.pathname);
            return true;
        }
        return false;
    };

    const pollSignInContractApi = async () => {
        try {
            const params = {
                fn: getSignContractConnect,
                validate: signInContractDataAdded,
                interval: 3000,
                maxAttempts: 10
            };
            await poll(params);
        } catch (error) {
            signContractPollStatusConnect(true);
            history.replace(window.location.pathname);
        }
    };

    const verifyIdDataAdded = (response) => {
        if (response) {
            verifyIdPollStatusConnect(true);
            history.replace(window.location.pathname);
            return true;
        }
        return false;
    };

    const pollUploadIdApi = async () => {
        try {
            const params = {
                fn: getVerificationStatusConnect,
                validate: verifyIdDataAdded,
                interval: 3000,
                maxAttempts: 10
            };
            await poll(params);
        } catch (error) {
            verifyIdPollStatusConnect(true);
            history.replace(window.location.pathname);
        }
    };

    useEffect(() => {
        if (orderId) {
            pollSignInContractApi();
            const isInHouseFinance = financeProvider === LOAN_PROVIDER.CARS24 && financeOpted;
            if (!isInHouseFinance) {
                pollUploadIdApi();
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financeOpted, financeProvider, orderId]);

    const validateSignContractData = (response, attempts) => {
        if (response.data.viewUrl) {
            window.location.href = response.data.viewUrl;
        } else if (attempts === NUMBER.SIX) {
            setDocuSignLoading(false);
            showToastConnect({ variant: "black", text: "Something went wrong. Please try again or contact support..", position: "middle"});
        }
        return false;
    };
    const handleDocuSignClick = async () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CONTRACT_OF_SALE);
        setDocuSignLoading(true);
        const params = {
            orderId,
            documentType
        };
        const pollingParams = {
            fn: postSignContractConnect,
            validate: validateSignContractData,
            interval: 5000,
            maxAttempts: 6,
            fnParams: params,
            retryOnFail: true
        };
        await poll(pollingParams);
    };

    return (
        <div styleName={"styles.modalwrapper"}>
            {docuSignLoading && <DocusignLoaderInfo />}
            <div styleName={"styles.modalHeader"}>
                <span styleName={"styles.backIcon"} onClick={handleBackClick}><ArrowIcon black={"BlackArrow"} rotateBy={90} /></span>
                <p>{`${year || ""} ${make || ""} ${model || ""}`}</p>
            </div>
            <div styleName={"styles.taskWrapper"}>
                <h4 styleName={"styles.title"}>
                    <img src={ContractIcon} alt="Contract of sale" />
                    Contract of sale
                </h4>
                <div styleName={"styles.noteWrapper"}>
                    <p>Please Note:</p>
                    <ul>
                        <li>
                            <span>Keep your Driver’s Licence with you. It will come in handy while signing the contract</span>
                        </li>
                        <li>
                            <span>We’ll mail you the documents after successful signing</span>
                        </li>
                        <li>
                            <span>You will be redirected to <strong>Docusign</strong> where you can read and sign the contract</span>
                        </li>
                    </ul>
                </div>
                <Button disabled={!orderId} text={"READ AND SIGN "} onClick={handleDocuSignClick} />
            </div>
        </div>
    );
};

ContractSaleNote.propTypes = {
    content: PropTypes.object,
    order: PropTypes.object,
    signContractData: PropTypes.array,
    postSignContractConnect: PropTypes.func,
    updateSignContractStatusConnect: PropTypes.func,
    egcData: PropTypes.array,
    showToastConnect: PropTypes.func,
    customSignClick: PropTypes.func,
    updateDeliveryContractConnect: PropTypes.func,
    getVerificationStatusConnect: PropTypes.func,
    verifyIdPollStatusConnect: PropTypes.func,
    signContractPollStatusConnect: PropTypes.func,
    getSignContractConnect: PropTypes.func
};

export default ContractSaleNote;
