import loadable from "@loadable/component";
import ContractSaleNote from "../../components/au.mobile/contract-sale-note";
import GreenId from "../../components/shared/green-id";

// const TaskOverView =  loadable(() => import("../../components/au.mobile/order-summary-tasks"));
const PostOrderStatusWrapper =  loadable(() => import("../../components/au.mobile/post-order-status-wrapper"));
const OrderPaymentOverview =  loadable(() => import("../../components/au.mobile/order-payment-overview"));
const OrderPaymentSummary =  loadable(() => import("../../components/au.mobile/order-payment-summary"));
const orderConfirmedInfo =  loadable(() => import("../../components/au.mobile/order-confirmed-info"));
const FinanceLoanApplication =  loadable(() => import("../../components/au.mobile/finance-post-payment-loan-application"));
const FinanceUploadDocuments =  loadable(() => import("../../components/au.mobile/finance-upload-documents"));

import TestDriveSummary from "../../components/au.mobile/test-drive-booking-summary";
const BSBBankTransfer  =  loadable(() => import("../../components/au.mobile/bank-transfer"));

export const tasksRoutes = {
    viewTask: {
        key: "viewTask",
        name: "task",
        route: "tasks",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: PostOrderStatusWrapper,
        hideCurrentPill: false,
        useRoute: null,
        type: "tasks",
        subType: "trade-in",
        approx: 1,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    orderPaymentOverview: {
        key: "orderPaymentOverview",
        name: "task",
        route: "select-payment",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: OrderPaymentOverview,
        hideCurrentPill: false,
        useRoute: null,
        type: "tasks",
        subType: "trade-in",
        approx: 1,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "orderPaymentOverview"
        }
    },
    orderPaymentSummary: {
        key: "orderPaymentSummary",
        name: "task",
        route: "payment-summary",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: OrderPaymentSummary,
        hideCurrentPill: false,
        useRoute: null,
        type: "tasks",
        subType: "task",
        approx: 1,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "orderPaymentSummary"
        }
    },
    bankTransferDetails: {
        key: "bankTransferDetails",
        name: "Bank Transfer",
        route: "bank-transfer-details",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: BSBBankTransfer,
        hideCurrentPill: true,
        useRoute: null,
        type: "tasks",
        subType: "task",
        approx: 1
    },
    orderConfirmed: {
        key: "orderConfirmed",
        name: "order confirmed",
        route: "order-confirmation",
        gtmPageName: "confirmation",
        gtmPillClickLabel: "",
        defaultBack: "",
        defaultNext: "",
        component: orderConfirmedInfo,
        type: "hidden",
        approx: 0,
        sidebarNavigation: {
            heading: "Order confirmation",
            subHeading: "Order summary",
            navKey: "orderConfirmed"
        }
    },
    signContract: {
        key: "signContract",
        name: "sign contract",
        route: "sign-contract",
        gtmPageName: "sign-contract",
        gtmPillClickLabel: "",
        defaultBack: "tasks",
        defaultNext: "",
        component: ContractSaleNote,
        type: "hidden",
        approx: 0,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    verifyId: {
        key: "verifyId",
        name: "Verify Id",
        route: "verify-id",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultBack: "tasks",
        defaultNext: "",
        component: GreenId,
        type: "hidden",
        approx: 0,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    loanApplication: {
        key: "loanApplication",
        name: "Loan Application",
        route: "loan-application",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultBack: "tasks",
        defaultNext: "",
        component: FinanceLoanApplication,
        type: "hidden",
        approx: 0,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    uploadDocuments: {
        key: "uploadDocuments",
        name: "Upload Documents",
        route: "upload-documents",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultBack: "tasks",
        defaultNext: "",
        component: FinanceUploadDocuments,
        type: "hidden",
        approx: 0,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    testDriveSummary: {
        key: "testDriveSummary",
        name: "testDriveSummary",
        route: "test-drive-summary",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: TestDriveSummary,
        hideCurrentPill: false,
        useRoute: null,
        type: "testDriveSummary",
        approx: 1
    }

};

export const tasksRoutesArray = Object.values(tasksRoutes);

export const routesToRedirecttasks = tasksRoutesArray
    .filter((routeData) => routeData.type === "tasks")
    .slice(1)
    .map((routeData) => routeData.route);

export const routesToRedirectFinance = tasksRoutesArray
    .filter((routeData) => routeData.type === "financing")
    //.slice(1)
    .map((routeData) => routeData.route);

export const tradeInRoutes = tasksRoutesArray.filter((routeData) => routeData.subType === "trade-in")
    .map((routeData) => routeData.route);
