import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showToast } from "../../shared/toast-message/actions";
import { postSignContract, updateSignContractStatus, getSignContract, getVerificationStatus, signContractPollStatus, verifyIdPollStatus } from "../checkout-info/actions";
import { updateDeliveryContractData} from "../order-task-wrapper/actions";
import ContractSaleNote from "./component";

const mapStateToProps = ({
    carDetails: {
        content
    },
    checkout: {
        signContractData,
        isPostSignContractFetched,
        egcData,
        order
    }

}) => ({
    content,
    order,
    egcData,
    signContractData,
    isPostSignContractFetched
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    postSignContractConnect: postSignContract,
    updateSignContractStatusConnect: updateSignContractStatus,
    showToastConnect: showToast,
    updateDeliveryContractConnect: updateDeliveryContractData,
    getVerificationStatusConnect: getVerificationStatus,
    verifyIdPollStatusConnect: verifyIdPollStatus,
    signContractPollStatusConnect: signContractPollStatus,
    getSignContractConnect: getSignContract
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContractSaleNote);
